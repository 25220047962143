@import '../styles/mixins/loginRegisterMixins.scss';

.home_container {
  @include login-register;
  border: none;
  display: flex;
  flex-direction: column;

  ion-button{
    width: 300px;
  }
}

