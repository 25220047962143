@mixin login-register {
  max-width: 650px;
  width: 100%;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  border: 2px solid #989aa2;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  right: 0;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);

  ion-item{
    align-items: center;
    --inner-padding-end: 10px;
    --inner-padding-start: 10px;
  }
  @media screen and (max-width: 650px){
    border: none;
  }
}
