@import '../../styles/mixins/loginRegisterMixins.scss';

.login_container {
  @include login-register;

 .no_account {
   display: flex;
   justify-content: center;
   align-items: center;
   text-align: center;
   width: 100%;

   ion-button {
     --background: transparent;
     --background-focused: none;
     --box-shadow: none;
     --color: #000;
     border: 1px solid black;
     -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
     border-radius: 5px;
   }
 }

}
